
.carousel .carousel-caption {
  text-align: center;
  z-index: 100;
  width: 50%; 
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 60%; 
  padding: 0 1rem;
}


.carousel .carousel-caption h1 {
  font-size: 2.5rem;
  font-weight: bolder;
  color: #fff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.carousel .carousel-item {
  position: relative;
}
.carousel .carousel-item  img{
  height: 600px !important;
  object-fit: cover;
}


.carousel .carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.309);
  z-index: 1;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--dark);
  border: 12px solid var(--dark);
  border-radius: 3rem;
}


.carousel .btn {
  color: white;
  background-color: var(--secondary);
  border: none;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 50px;
  padding: 15px 30px;
  font-weight: bold;
  font-size: 15px;
}


/* Responsive styles */
@media (max-width: 768px) {
  .carousel  .carousel-item {
    position: relative;
    min-height: 450px;
}
  .carousel  .carousel-item img {
    /* position: relative; */
    min-height: 450px;
    object-fit: cover;
}

.carousel .carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); 
  z-index: 1; 
}

  .carousel .carousel-caption h1 {
    font-size: 1.5rem; 
  }

  .carousel .carousel-control-prev-icon,
  .carousel .carousel-control-next-icon {
    width: 2.5rem; 
    height: 2.5rem;
    border: 8px solid var(--dark);
  }

  .carousel .btn {
    font-size: 14px;
        padding: 15px 37px;
        position: relative;
        top: 150px;
}

.carousel .carousel-caption {
  text-align: center;
  z-index: 100;
  width: 100%;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
 
}


  .carousel .carousel-caption h1 {
    /* font-size: 30px;
    font-weight: 700; */
    line-height: 1.2;
    display: flex;
    justify-content: center;
    color: #fff; /* White color for better contrast */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
 
}
