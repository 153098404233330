.impact-page-content {
    padding: 20px 0;
  }
  
  .project-list {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
  }
  
  .project-card {
    margin-bottom: 30px;
    text-align: center;
    padding: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .project-flyer {
    width: 100%; 
    height: 250px; 
    object-fit: cover; 
    max-width: 100%; 
    border-radius: 8px;
  }

  
  @media (max-width: 768px) {
    .project-flyer {
      height: 400px; 
    }
    .read-more-btn {
        display: inline-block;
        margin-top: 15px;
        padding: 5px 10px;
        background: linear-gradient(to right, rgb(10, 69, 145), rgb(10, 10, 11));
        color: white;
        border: none;
        border-radius: 40px;
        cursor: pointer;
        /* transition: all 0.3s ease; */
    }
  }
  
  @media (max-width: 576px) {
    .project-flyer {
      height: 400px; 
    }
    
    .read-more-btn {
        display: inline-block;
        margin-top: 15px;
        padding: 5px 10px !important;
        background: linear-gradient(to right, rgb(10, 69, 145), rgb(10, 10, 11));
        color: white;
        border: none;
        border-radius: 40px !important;
        cursor: pointer;
        /* transition: all 0.3s ease; */
    }
  }
  
  .project-details {
    margin-top: 15px;
    text-align: left;
  }
  
  .project-details h3 {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #333;
  }
  
  .project-details p {
    font-size: 1.1rem;
    color: #555;
  }
  
  .icon-text {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .icon-text svg {
    margin-right: 8px;
    color: #0730c8fb;
  }
  
  .read-more-btn {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 15px !important;
    background: linear-gradient(to right, rgb(10, 69, 145), rgb(10, 10, 11));
    color: white;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .read-more-btn:hover {
    background-color: #0056b3;
    border-radius: 5px;
    padding: 6px 12px;
  }
  
  @media (min-width: 992px) {
    .col-lg-3 {
      flex: 0 0 24%;
      max-width: 24%;
    }
    .read-more-btn {
        display: inline-block;
        margin-top: 15px;
        padding: 5px 10px !important;
        background: linear-gradient(to right, rgb(10, 69, 145), rgb(10, 10, 11));
        color: white;
        border: none;
        border-radius: 40px !important;
        cursor: pointer;
        /* transition: all 0.3s ease; */
    }
  }
  