/* General styles */
.team-carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .team-carousel-container {
    padding: 0 15px;
  }

}

.team-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.team-member.animate {
  animation: fadeInUp 0.5s forwards;
}

.team-image {
  width: 100%;
  height: 340px;
  object-fit: cover;
  margin-bottom: 15px;
}
@media (max-width: 768px){
  
.team-image {
  width: 300px;
  height: 400px;
  object-fit: contain;
  margin-bottom: 15px;
}
}

.team-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.team-role {
  font-size: 16px;
  color: #555;
  text-align: center;
}

/* Hide bottom row when scrolling up */
.bottom-row {
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 1;
}

.bottom-row.hide {
  opacity: 0;
  transform: translateY(20px);
}

.bottom-row.show {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .team-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
