@import url('https://fonts.googleapis.com/css2?family=Sono:wght@200..800&display=swap');

.contact-box {
    /* background-color: beige; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Top section styles */
.top-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    text-align: center;
}

.top-section h1 {
    font-weight: bolder;
}

.top-section p {
    font-size: 1.1rem;
    margin-top: 10px;
}

/* Bottom section (Contact Info and Form) */
.bottom-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 30px;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    flex-wrap: wrap; /* Allow items to wrap */
}
.contact-info {
    width: 37%; /* Adjust width to be responsive */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;
    padding: 10% 5%;
    border-radius: 15px 15px 0px 15px;
}

.contact-form {
    width: 48%; /* Adjust width to be responsive */
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
}

/* Adjustments for form items */
.form-item {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 10px;
}

.form-group {
    flex: 1;
    margin-right: 10px;
}

.form-group:last-child {
    margin-right: 0;
}

.form-control-contact {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}

/* Remove outline, border, and shadow when input is focused */
.form-control-contact:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

/* Remove hover effects */
.form-control-contact:hover {
    outline: none;
    border: none;
    background-color: transparent;
}

hr {
    border: none;
    border-top: 2px solid #ccc;
    margin: 0;
}

textarea.form-control-contact {
    resize: none;
}

.btn-1 {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.btn-1:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .bottom-section {
        flex-direction: column;
        align-items: center;
    }
    
    .contact-info, .form-control-contact {
        width: 100%; /* Full width on small screens */
        padding: 20px;
    }

    .form-row {
        flex-direction: column;
        gap: 20px;
    }

    .contact-form {
        width: 100%;
        /* margin: 0 auto;
        padding: 20px;
        border-radius: 8px; */
    }
}
