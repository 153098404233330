.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(0, 255, 141, 0.05); /* This will maintain the existing semi-transparent green background */
  background-image: url('../../Assets/Images/bg-txt.jpg'); /* Add your background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
  position: relative; /* Establish a positioning context for absolute elements */
  overflow: hidden; /* Ensure that no overflow occurs */
}

.testimonials-title {
  text-align: center;
  margin-bottom: 30px;
}

.testimonials-slider {
  position: relative;
  overflow: hidden;
  background: #ffffff;
}

.testimonials-grid {
  display: flex;
  transition: transform 0.5s ease;
}

.testimonial-card {
  flex: 0 0 25%;
  padding: 20px;
  text-align: center;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.testimonial-name {
  font-family: monospace;
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 5px;
}

.testimonial-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.fw-bolder {
  font-family: "Ubuntu", sans-serif;
  font-size: 35px;
}

.testimonial-quote {
  font-style: italic;
}

.prev-arrow,
.next-arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.9); /* Slightly darker white */
    border: none;
    font-size: 24px;
    padding: 10px 15px; /* Add horizontal padding for better size */
    cursor: pointer;
    border-radius: 50%; /* Make the buttons round */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add a slight shadow for depth */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

/* Change the color slightly on hover */
.prev-arrow:hover,
.next-arrow:hover {
    background-color: rgba(240, 240, 240, 0.9); /* Darker color on hover */
}

.prev-arrow {
    left: 10px;
}

.next-arrow {
    right: 10px;
}




/* Media query for small screens */
@media (max-width: 768px) {
  .testimonials-grid {
      flex-wrap: nowrap;
  }

  .testimonial-card {
      flex: 0 0 100%;
  }

  .prev-arrow,
  .next-arrow {
      display: block;
  }
  
  .testimonial-image {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-bottom: 15px;
    object-fit: cover;
}
}

/* Animation for auto-sliding on large screens */
@media (min-width: 769px) {
  @keyframes slide {
      0% {
          transform: translateX(0);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .testimonials-grid {
      animation: slide 20s linear infinite;
  }

  .testimonials-grid:hover {
      animation-play-state: paused;
  }
}
