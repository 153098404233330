/* General gallery styling */
.gallery .gallery-container {
  padding: 20px;
}

.gallery .gallery-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: bold;
}

.gallery .gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.gallery .gallery-item {
  cursor: zoom-in;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.gallery .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

.gallery-item.large {
  grid-column: span 2;
  grid-row: span 2;
}

.gallery .modal {
  display: block;
  position: fixed;
  z-index: 1000;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.gallery .modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 1000px;
  max-height: 80%;
  object-fit: cover;
}

.gallery .close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.gallery .modal-caption {
  text-align: center;
  padding: 10px;
  font-size: 1.2em;
  color: white;
}

/* Previous & Next buttons */
.gallery .prev, .gallery .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 40px;
  user-select: none;
  transition: 0.3s ease;
}

.gallery .prev {
  left: 0;
}

.gallery .next {
  right: 0;
}

.gallery .prev:hover, .gallery .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Hide arrows on mobile screens */
@media (max-width: 600px) {
  .gallery .prev, .gallery .next {
    display: none; /* Hide arrows on mobile */
  }

  .gallery .modal-content {
    width: 90%;
    max-height: 70%;
    position: relative;
    top: 30px;
  }
}
