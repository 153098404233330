/* src/components/Home.css */
.home .home-container {
  width: 100%;
  height: 100%;
}

.home .background-texture-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 2rem;
  height: 20vh;
}

.home .bg-image {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
}

.home .service-item {
  background-color: rgba(255, 255, 255, 0.9);
  /* Slightly transparent white background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Optional: Add some shadow for depth */
}

/* Container for the button and the image */
.home .position-relative {
  position: relative;
  overflow: hidden;
}

.home .fw-bolder{
  font-family: "Ubuntu", sans-serif;
  font-size: 35px;
}
.home .m-f{
  font-family: "Ubuntu", sans-serif;
  font-size: 24px;
}

.home .causes-overlay {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.4s ease;
  opacity: 0; /* Initially hidden */
}

.home .causes-item:hover .causes-overlay {
  opacity: 1; /* Make the button visible */
  bottom: 40px; /* Move the button up */
}

/* Button styling */
.home .btn-outline-primary {
  transition: all 0.3s ease;
}

.home .causes-item:hover .btn-outline-primary {
  transform: translateY(0);
  opacity: 1;
  background-color: aliceblue;
  color: #000;
  border: white;
}

/* Add hover effects on the card itself */
.home .causes-item {
  transition: all 0.3s ease;
}

.home .causes-item:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Make the button appear smoothly from the bottom */
.home .btn-outline-primary {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.4s ease;
}

.home .causes-item:hover .btn-outline-primary {
  transform: translateY(0); /* Move it up on hover */
  opacity: 1; /* Make it visible */
}

.home .project-item {
  transition: all 0.3s ease;
  overflow: hidden;
}


.home .project-item.pipeline {
  background-color: #aa1d1d;
  color: #fff;
}

.home .project-item.pipeline .project-text {
  background: linear-gradient(to right, #0a4591 0%, #0a0a0b 100%);
}

.home .project-item.pipeline h4,
.project-item.pipeline p {
  color: #fff;
}

.home .project-item.upcoming {
  background-color: #f8f9fa;
}

.home .project-item:hover {
  transform: translateY(-5px);
}

.home .project-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
  .home .project-item img {
    height: auto;
  }

}


/* Media query for mobile screens */
@media (max-width: 480px) {
  .home .project-item img {
    height: auto;
  }

  .home .background-texture-section {
    padding: 1rem;
    height: 15vh;
  }

  .home .btn-coming-soon {
    font-size: 14px;
  }

  .home .finished-sign {
    font-size: 12px;
    padding: 8px 16px;
  }
  .home .fw-bolder{
    font-family: "Ubuntu", sans-serif;
    font-size: 20px;
  }

  .home .m-f {
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
}
}

.home .project-text {
  background-color: #f8f9fa;
}

.home .btn-coming-soon {
  background-color: white;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.home .btn-coming-soon:hover {
  background-color: #0056b3;
  color: white;
}

.home .project-item {
  position: relative;
  overflow: hidden;
}

.home .project-image-container {
  position: relative;
}

.home .project-item img {
  transition: filter 0.3s ease;
}

.home .project-item.finished img {
  filter: blur(3px);
}

.home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: none;
}

.home .project-item.finished .overlay {
  opacity: 1;
}

.home .finished-sign {
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}



.animated-text span {
  display: inline-block;
  animation: fadeIn 1s ease forwards;
  animation-delay: calc(var(--i) * 0.1s);
  opacity: 0;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(-10px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}


.C {
  position: relative;
  top: 10px;
}

.modal.fade.show {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 1rem);
  margin: 1rem;
}

.modal-content {
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 500px;
  width: 100%;
}

.modal-header {
  background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
  /* border-bottom: 1px solid #dee2e6; */
  padding: 0.75rem 1rem;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}
.alert-btn-close {
  background: transparent; 
  border: none;
  cursor: pointer; 
  padding: 0;font-size: larger;
}

.alert-btn-close:focus {
  outline: none; /* Remove outline on focus */
}


.modal-body {
  padding: 0.75rem 1rem;
}

.alert-card {
  display: flex;
  gap: 20px; 
  align-items: flex-start;
}

.alert-flyer {
  width: 100%;
  max-width: 250px; /* Increased flyer size */
  height: auto;
  border-radius: 8px;
}

.alert-project-details {
  flex: 1;
  font-size: 15px;
  font-weight: 600; 
  display: flex;
  flex-direction: column; 
  font-family: "Ubuntu", sans-serif;
}

.fs-20 {
  font-size: 1.25rem; /* Adjusted size for the project title */
  margin-bottom: 10px; /* Add margin below title for spacing */
}

.project-extra-details {
  margin-top: 10px; /* Increased margin for better spacing */
}

.icon-text {
  display: flex;
  align-items: center;
  gap: 10px; /* Increased gap between icon and text */
}

@media (max-width: 768px) {
  .alert-card {
    flex-direction: column;
 
  }

  .alert-flyer {
    max-width: 100%;
  }

  .modal-content {
    border-radius: 0.5rem;
    overflow: hidden;
    max-width: 360px;
    width: 100%;
    max-height: 641px;
  }

  .project-details {
    align-items: center; 
    font-size: 15px;
  }
  /* .modalstyle {
    position: relative;
    top: 150px;
  } */
 
}

.modal-footer {
  padding: 0.75rem 1rem;
  background-color: #f1f1f1;
  border-top: 1px solid #dee2e6;
}
