/* Center the caption text */
.carouselTwo .carousel-caption {
    text-align: center;
    z-index: 100;
    width: 50%; 
    left: 50%;
    top: 30%;
    transform: translateX(-50%); /* Center horizontally */
    position: absolute;
    bottom: 60%; /* Adjusted to move the caption higher */
    padding: 0 1rem;
  }
  
  /* Style the h1 for clarity */
  .carouselTwo .carousel-caption h1 {
    font-size: 2.5rem; /* Default font size */
    font-weight: bolder;
    color: #fff; /* White color for better contrast */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  /* Overlay on carousel items */
  .carouselTwo .carousel-item {
    position: relative;
  }
  .carouselTwo .carousel-item  img{
    height: 400px !important;
    object-fit: cover;
  }
  
  .carouselTwo .carousel-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.419); /* Lighter overlay to make the caption stand out more */
    z-index: 1; /* Overlay above the image but below the text */
  }
  
  .carouselTwo .carousel-control-prev-icon,
  .carousel .carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--dark);
    border: 12px solid var(--dark);
    border-radius: 3rem;
  }
  
  /* Style for buttons */
  .carouselTwo .btn {
    color: white;
    background-color: var(--secondary);
    border: none;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 50px;
    padding: 15px 30px;
    font-weight: bold;
    font-size: 15px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .carouselTwo  .carousel-item {
      position: relative;
      min-height: 450px;
  }
    .carouselTwo  .carousel-item img {
      /* position: relative; */
      min-height: 450px;
      object-fit: cover;
  }
  
  .carouselTwo .carousel-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); 
    z-index: 1; 
  }
  
    .carouselTwo .carousel-caption h1 {
      font-size: 1.5rem; 
    }
  
    .carouselTwo .carousel-control-prev-icon,
    .carousel .carousel-control-next-icon {
      width: 2.5rem; 
      height: 2.5rem;
      border: 8px solid var(--dark);
    }
  
    .carouselTwo .btn {
      font-size: 14px;
      padding: 15px 37px;
  }
  
  .carouselTwo .carousel-caption {
    text-align: center;
    z-index: 100;
    width: 100%;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
   
  }
  
  
    .carouselTwo .carousel-caption h1 {
      font-size: 30px;
      font-weight: 700;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      color: #fff; /* White color for better contrast */
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
   
  }
  