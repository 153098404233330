@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sono:wght@200..800&display=swap');
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Questrial", sans-serif;
    /* font-family: 'Helvetica Neue', sans-serif; */
    /* font-family: "Space Mono", monospace; */
  }

h1 {
  font-family: "Sono", monospace;
    font-weight: bold;
}

.owl-carousel .item {
  background: #4a90e2;
  padding: 30px;
  text-align: center;
  color: white;
  font-size: 18px;
}