@import url('https://fonts.googleapis.com/css2?family=Sono:wght@200..800&display=swap');

.footer-container .footer {
    background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
    color: #888888;
    padding-top: 20px;
    padding: 0 0;
}
.footer-container .footer-logo{
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}
.footer-container .fw-bold{
    font-weight: bolder;
    font-size: 20px;
    font-family: "Sono", monospace;
}
.footer-container .charity{
    /* font-weight: bolder; */
    font-size: 12px !important;
    font-family: "Sono", monospace;
}
  
  .footer-container .text-footer {
    color: #f5f5f5;
  }
  .footer-container .Quick-links{
    display: flex;
    flex-direction: column;
  }

  .footer-container .no-underline {
    text-decoration: none;
    color: #ffffff; /* Or your preferred color */
  }
  
  .footer-container .no-underline:hover {
    color: #f5f5f5; /* Or your hover color */
  }
  
  .footer-container .btn-square {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    padding: 0;
    background-color: #131313;
    border: 1px solid #888888;
    transition: background-color 0.2s ease-in-out;
  }
  
  .footer-container .btn-square:hover {
    background-color: #888888;
    color: #f5f5f5;
  }
  .footer-container .footer-hr {
    border: 0;
    border-top: 1px solid #555;
    margin: 20px 0;
    width: 100%;
  }
 .m-f{
  font-family: "Sono", monospace;
    font-size: 24px;
  }
  .footer-container .copyright {
    background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
    padding: 20px 0;
  }
  