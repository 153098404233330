@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

:root {
  --primary: #2ad939;
  --secondary: #0E4C9D;
  --light: #F8F8F9;
  --dark: #001D23;
  --bgColor: #42424200;

}


.about .about-container {
    width: 100%;
    height: 100%;
  }

 .about .text-container .btn.no-radius {
  border-radius: 0; 
} 
.about .f-abt{
  margin-top: 15px;
    font-size: 30px;
    font-weight: bolder;
    font-family: "Sora", sans-serif;
    background-image: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
    -webkit-background-clip: text; 
    color: transparent; 
}
.about .f-abt-l{
  font-size: 20px;
  font-weight: bolder;
  font-family: "Sora", sans-serif;
  background-image: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
  -webkit-background-clip: text; 
  color: transparent; 
}
.about .background-texture-section{
  /* background-image: url(/img/bg-txt.jpg); */
  background-size: cover;
  background-position: center;
  padding: 20px 0;
  width: 100%;
  color: #ffffff;
  background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
}
.about .background-texture-section h2{
  font-family: "Sora", sans-serif;
}
.about .small-header{
  width: 100%;
  height: 100%;
 font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: var(--primary);
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}
.abt-fs{
  font-size: 35px;

}
.about .f-abt-s{
  font-size: 14px;
  font-family: 'Sora';
  font-weight: 500;

}


.about .learn-more-content {
  max-height: 0; /* Initially hidden */
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.about .learn-more-content.expanded {
  max-height: 500px; /* Set to a height large enough to fit the content */
}

.about .learn-more-content.collapsed {
  max-height: 0;
}

.about .btn {
  background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
  transition: background-color 0.3s ease;
}

.about .btn:hover {
  background-color: #007bff;
}

@media (max-width: 768px) {
  .abt-fs{
    font-size: 20px;
    
  } 
  .about .f-abt {
    margin-top: 15px;
    font-size: 20px;
    font-weight: bolder;
    font-family: "Sora", sans-serif;
}

.about .f-abt-l{
  font-size: 18px;
  font-weight: bolder;
  font-family: "Sora", sans-serif;
  background-image: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
  -webkit-background-clip: text; 
  color: transparent; 
}
}