/* General styling for the container */
.project-details-container {
  display: flex;
  padding: 20px;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

/* Styling for the image */
.founder-image {
  flex: 1;
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.founder-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* Project info styling */
.project-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  text-align: left; /* Align the text to the left */
}

.project-info h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-info h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #555;
}

.project-info p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: left; /* Ensure description is aligned to the left */
}

.action-button,
.back-button {
  background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  display: inline-flex;  /* Inline flex to keep the icon and text aligned */
  align-items: center;   /* Vertically center icon and text */
  gap: 10px;             /* Add space between the icon and the text */
  text-decoration: none; /* Remove underline for link button */
}

.action-button:hover,
.back-button:hover {
  background-color: #0056b3;
}

/* Make the icon size a bit smaller */
.button-icon {
  font-size: 1.2em; /* Adjust the size of the icon */
}
/* Button container styling */
.button-container {
  display: flex;
  gap: 8px; /* Add space between buttons */
  justify-content: flex-start; /* Align buttons to the left */
  margin-top: 20px; /* Add some margin above the buttons */
}

/* Styling for smaller mobile devices */
@media (max-width: 768px) {
  .project-details-container {
    flex-direction: column; /* Stack items vertically */
    padding: 10px; /* Adjust padding */
  }

  /* Make the action button smaller */
  .action-button,
  .back-button {
    padding: 10px 15px; /* Smaller padding */
    font-size: 14px;   /* Smaller font size */
  }

  /* Ensure text is aligned to the left on small screens */
  .project-info p,
  .project-info h3,
  .project-info h1 {
    text-align: left;
  }
}

/* Styling for extra small mobile devices */
@media (max-width: 480px) {
  .action-button,
  .back-button {
    padding: 10px 12px; /* Even smaller padding */
    font-size: 12px;   /* Smaller font size */
  }

  .project-info h1 {
    font-size: 1.5rem; /* Smaller title font */
  }

  .project-info h3 {
    font-size: 1.2rem; /* Smaller subheading font */
  }

  .project-info p {
    font-size: 0.9rem; /* Slightly smaller paragraph text */
  }
}
