.donate {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../../../Assets/Images/carousel-2.jpg'); /* Add your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.donate .donation-form-container {
  background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.donate h1 {
  font-family: 'Ubuntu';
  font-weight: bolder;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.donate  .form-type-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.donate  .form-type-selector label {
  margin-right: 10px;
  font-size: 16px;
}

.donate  .form-group {
  margin-bottom: 15px;
}

.donate  label {
  display: flex;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

.donate  input, select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.donate  .btn {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donate .btn:hover {
  background-color: #218838;
}