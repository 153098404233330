@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

:root {
  --primary: #2ad939;
  --secondary: #0E4C9D;
  --light: #F8F8F9;
  --dark: #001D23;
  --bgColor: #42424200;

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  color: #001D23;
}

.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 30px;
}

.logo img {
  height: 30px; /* Adjust the logo size */
}

.logo a {
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: bolder;
  text-decoration: none;
  font-family: "Sora", sans-serif;
  background-image: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
  -webkit-background-clip: text; 
  color: transparent; 
}

.logo span {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: bolder;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0 auto;
}

.nav-links li a {
  color: var(--secondary);
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: var(--primary); /* Change color on hover */
}

.donate-btn {
  background: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
  color: white !important;
  padding: 10px 32px;
  text-decoration: none;
  font-size: 15px;

  position: absolute;
  right: 20px;
}

.donate-btn:hover {
  background-color: #7e7e7d;
}

/* Dropdown menu styling */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  min-width: 202px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  padding-left: 0%;
}

.dropdown-content li {
  padding: 12px 16px;
}

.dropdown-content li a {
  color: var(--secondary);
  text-decoration: none;
  display: block;
  font-size: 16px;
  list-style-type: none;
}

.dropdown-content li a:hover {
  /* background-color: #555; */
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Hamburger menu for mobile view */
.hamburger-menu {
  height: 20px;
  width: 25px;
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 20px;
  height: 15px;
  background-color: rgb(0, 0, 0);
  margin: 4px 0;
}


/* Close button for the menu */
.close-btn {
  position: absolute;
  right: 30px;
  top: 7px;
  font-size: 30px;
  color: white;
  display: none;
  cursor: pointer;
}

/* Arrow icon rotation for dropdown */
.arrow-icon {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.dropdown:hover .arrow-icon {
  transform: rotate(180deg);  /* Rotate the arrow when hovering over the dropdown */
}

/* Dropdown content styling */
.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block; 
}




@media (max-width: 768px) {
  .dropdown-content {
    display: none; /* Hide by default */
    opacity: 0; /* Set initial opacity */
    transition: opacity 0.3s ease, max-height 0.3s ease; /* Transition for smooth effect */
    max-height: 0; /* Collapse height */
    overflow: hidden; /* Hide overflow */
    list-style-type: none;
    padding-left: 0%;
  }
  
  .dropdown.active .dropdown-content {
    display: block;
    opacity: 1; 
    max-height: 300px;
    max-width: 200px;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #333;
    position: absolute;
    top: 68px;
    left: 0;
    z-index: 1;
    height: 1000px;
  }

  .nav-links.active {
    display: flex;
    align-items: center;
    padding: 0%;
    background-color: var(--secondary);
  }
  .nav-links.active a {
    font-size: 28px;
    color: white;
  }
.lt-drop{
  font-size: 21px !important;
  color: #007bff !important;
}

  .hamburger-menu {
    display: flex;
  }

  .donate-btn {
    position: static;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    position: static;
  }

  .logo img {
    height: 25px; 
  }
  
  .logo a {
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-weight: bolder;
    text-decoration: none;
    font-family: "Sora", sans-serif;
    background-image: linear-gradient(to right, rgb(10, 69, 145) 0%, rgb(10, 10, 11) 100%);
    -webkit-background-clip: text; 
    color: transparent; 
  }
  
  .logo span {
    color: rgb(0, 0, 0);
    font-size: 10px;
    font-weight: 600;
  }

  .navbar {
    /* height: 100px;
    width: 100%; */
    /* justify-content: center; */
    padding: 5px 17px !important;
  }

  /* .dropdown-content {
    position: static;
    box-shadow: none;
    background-color: #333;
  } */

  .close-btn {
    display: block;
    color: #001D23;
    font-size: 40px;
  }

  .dropdown-content li a {
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .project-item img {
      height: auto;
  }
}

.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem; /* Adjust icon size */
}

.scroll-button:hover {
  background-color: #0056b3;
}

/* Ensure the scroll button doesn't overlap the navbar or mobile menu */
.navbar.sticky + .scroll-button {
  bottom: 60px;
}
