.banner .banner-container {
    position: relative;
    background-image: url('../Banner/Images/gallery-43.JPG'); 
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .banner  .jumbotron {
    position: relative;
    z-index: 1; 
    width: 100%;
    padding: 80px 20px;
    background: rgba(0, 0, 0, 0.773);
  }
  .banner .fw-bolder {
    font-family: "Sono", monospace;
    font-size: 50px;
}
  .banner  .overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 0; /* Place the overlay below the content */
  }
  @media (max-width: 768px) {
  .banner-container {
    height: 300px;
  }

  .banner  .jumbotron {
    position: relative;
    z-index: 1; 
    width: 100%;
    padding: 77px 20px;
    background: rgb(0 0 0 / 64%);
  }
  .banner .fw-bolder {
    font-family: "Sono", monospace;
    font-size: 25px;
}

  .jumbotron h1 {
    font-size: 2.5rem;
  }

  .jumbotron p {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .banner-container {
    height: 250px;
  }
}